<template>
    <Modal :options="options" :modal-class="'video'" @close="emit('confirm')">
        <Video
            class="video--modal"
            :videoClass="'vid1'"
            :src="videoSrc"
            :controls="true"
            :autoplay="autoplay"
            :fluid="false"
            :is-loader="true"
        >
	        <template #repeatBtn="{ player, state }">
                <Button
                    v-if="!state.playing && state.currentTime === 0"
                    class="custom-player-controls-play"
                    :btn-style="'primary-night'"
                    :btn-size="'medium'"
                    :btn-form="'round'"
                    :icon-name="'play-big'"
                    @click="player.play()"
                    aria-label="Смотреть видео"
                />
		        <Button
			        v-if="state.ended"
			        class="custom-player-controls-ended"
			        :btn-style="'primary-night'"
			        :btn-size="'medium'"
			        :btn-form="'round'"
			        :icon-name="'replay'"
			        @click="player.play()"
		        />
	        </template>
        </Video>
    </Modal>
</template>

<script setup lang="ts">
import Video from '~/components/ui/Video.vue';
import Modal from '~/components/ui/Modal.vue';
import type { IModalProps } from '~/types';
import Button from "~/components/ui/Button.vue";

defineProps({
    videoSrc: { type: String, default: '', required: true, readonly: true },
    autoplay: { type: Boolean, default: true, required: false, readonly: true },
});

const options: IModalProps = {
    teleportTo: 'body',
    contentTransition: 'vfm-fade',
    overflowTransition: 'vfm-fade',
    escToClose: true,
    clickToClose: true,
    class: 'modal-wrap',
    contentClass: 'modal-content',
    overlayClass: 'modal-overlay',
};

const emit = defineEmits(['update:modelValue', 'confirm', 'closed']);
</script>

<style lang="scss" src="~/assets/scss/components/ui/modal.scss"></style>
